<template>
  <div>
    <header class="mch__form-header">
      <div class="mch__form-title">{{ $t("piggybank.createModal.personalData") }}</div>
    </header>
    <div class="bb-form-fieldset">
      <InputText
        v-model="nombre"
        :label="$t('generic.name')"
        :placeholder="$t('generic.name')"
        v-bind:initialValue="getFirstStepData.nombre"
        required
      />

      <InputText
        v-model="apellidos"
        :label="$t('generic.surname')"
        :placeholder="$t('generic.surname')"
        v-bind:initialValue="getFirstStepData.apellidos"
        required
      />

      <InputText v-model="telefono"
                 :label="$t('generic.phoneNumber')"
                 placeholder="666555444"
                 required
                 v-bind:initialValue="getFirstStepData.telefono"
                 :errorLabel="errorPhoneNumber"
                 :inputmode="'numeric'"
                 @input="validateTelefonoOnInput()"
      />

      <InputText
        v-model="fechaNacimiento"
        :label="$t('generic.birthday')"
        type="date"
        :placeholder="$t('generic.birthday')"
        v-bind:initialValue="getFirstStepData.fechaNacimiento"
        required
        :errorLabel="errorBirthDate"
        @input="validateBirthDateOnInput(fechaNacimiento)"
      />
    </div>

    <footer class="mch__actions">
      <button @click="nextStep" class="button button--primary button--block button--icoEnd" :disabled="!isFormValid()">
        {{ $t('generic.next' )}} <i class="uil uil-arrow-right"></i>
      </button>
    </footer>
  </div>
</template>

<script>
import InputText from "../../../components/forms/InputText.vue";
import InputSelect from "../../../components/forms/InputSelect.vue";
import CrearDisclaimerStripe from "./CrearDisclaimer";
import error from "../../Error.vue";
import { mapGetters } from "vuex";

export default {
  name: "CrearStepIban",
  components: {
    CrearDisclaimerStripe,
    InputText,
    InputSelect
  },
  computed: {
    ...mapGetters({
      user: "user",
      getFirstStepData: "getFirstStepData",
      userCountry: "userCountry",
    })
  },
  data() {
    return {
      cuentaBancaria: "",
      moneda: "eur",
      nombre: "",
      apellidos: "",
      fechaNacimiento: "",
      errorIBAN: "",
      errorBirthDate: "",
      errorPhoneNumber: "",
      telefono: ""
    };
  },
  created() {
    this.initFormData();
  },
  methods: {
    async nextStep() {
      window.mixpanel.track("Activar vidriola pas IBAN");
      const firstStepData = {
        cuentaBancaria: this.cuentaBancaria,
        moneda: this.moneda,
        nombre: this.nombre,
        apellidos: this.apellidos,
        fechaNacimiento: this.fechaNacimiento,
        telefono: this.telefono

      };

      if (firstStepData) {
        await this.$store.dispatch("iniciarProceso", firstStepData);
        this.$emit("next-step", 2, firstStepData);
      } else {
        console.error("Data is undefined or null");
      }
    },
    initFormData() {
      if (this.getFirstStepData) {
        this.cuentaBancaria = this.getFirstStepData.cuentaBancaria || "";
        this.moneda = this.getFirstStepData.moneda || "eur";
        this.nombre = this.getFirstStepData.nombre || "";
        this.apellidos = this.getFirstStepData.apellidos || "";
        this.fechaNacimiento = this.getFirstStepData.fechaNacimiento || "";
        this.telefono = this.getFirstStepData.telefono || "";

      }
    },
    isFormValid() {
      return (
        this.errorBirthDate === "" &&
        this.moneda &&
        this.nombre &&
        this.apellidos &&
        this.fechaNacimiento
        && this.validateTelefono()
      );
    },
    validateBirthDateOnInput(fechaNacimiento) {
      let date = new Date(fechaNacimiento);
      let today = new Date();
      let age = today.getFullYear() - date.getFullYear();
      let m = today.getMonth() - date.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < date.getDate())) {
        age--;
      }
      if (age < 18) {
        this.errorBirthDate = this.$t("piggybank.createModal.birthdayError18");
      } else if (age > 100) {
        this.errorBirthDate = this.$t("piggybank.createModal.birthdayError");
      } else {
        this.errorBirthDate = "";
      }
    },
    validateTelefono() {
      switch (this.userCountry) {
      case "FR":
        return this.telefono.length >= 9 && this.telefono.length <= 10;
      case "BE":
        return this.telefono.length >= 9 && this.telefono.length <= 9;
      case "CH":
        return this.telefono.length >= 9 && this.telefono.length <= 10;
      case "LU":
        return this.telefono.length >= 4 && this.telefono.length <= 9;
      case "IT":
        return this.telefono.length >= 6 && this.telefono.length <= 11;
      case "ES":
      default:
        return this.telefono.length === 9;
      }
    },
    validateTelefonoOnInput() {
      if (this.validateTelefono()) {
        this.errorPhoneNumber = "";
      } else {
        this.errorPhoneNumber = this.$t("piggybank.createModal.errorPhoneDigits");
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
