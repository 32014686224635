<template>
  <div class="bb-form-item">
    <div class="bb-form-label" v-if="label" :for="id" :aria-label="label">
      {{ label }}<template v-if="required">*</template>
    </div>
    <div class="bb-form__input">
      <select :id="id" class="bb-form-select" v-model="modelValue">
        <option disabled value="" selected >{{ placeholder }}</option>
        <option v-for="option in options" :key="option.value" :value="option.value">
          {{ option.label }}
        </option>
      </select>
    </div>
    <div class="bb-form-error" v-if="error">
      <template v-if="errorLabel">{{ errorLabel }}</template>
      <template v-else>Lo sentimos, el campo es obligatorio</template>
    </div>
  </div>
</template>

<script>
export default {
  name: "InputSelect",
  props: {
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: [String, Number],
      default: null,
    },
    label: {
      type: String,
      default: false,
    },
    placeholder: {
      type: String,
    },
    id: {
      type: String,
    },
    required: {
      type: Boolean,
    },
    header: String,
    error: String,
    errorLabel: String,
    icoStart: String,
    initialValue: [String, Number],
  },
  computed: {
    modelValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },

  watch: {
    value(newValue) {
      this.modelValue = newValue;
    },
    initialValue(newValue) {
      this.modelValue = newValue;
    },
  },
  created() {
    if (this.initialValue !== undefined) {
      this.modelValue = this.initialValue;
    } else {
      this.modelValue = '';
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
